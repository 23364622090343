import React, { useState, useImperativeHandle, useRef } from 'react';
import { Button, Input, Msg, Upload,Tooltip,Icon } from "@tencent/universe-ui/build/lib";
import { isLogin } from '../../utils/index.js';
import { baseOcrRequest } from '../../utils/api.js';
const URL_EXP = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;


const FileToBase64 = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
};

function getFileType(url,acceptList) {
  let acList = acceptList.filter((i) => { return url.indexOf(i) > -1} )
  //获取文件类型
  console.log('acList',acList[acList.length - 1]);
  return acList.length > 0 ? acList[acList.length - 1] : ''
}


export default ({ acceptList = ['jpg', 'jpeg', 'png'], prefix = "Image", onSuccess, maxLength = 7, cRef }) => {
  useImperativeHandle(cRef, () => ({
    onUpload: () => {
      clickBtn?.current.click()
    }
  }));
  const [progress, setProgress] = useState(null);
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState('');
  const [visible, setVisible] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const clickBtn = useRef();


  const beforeUpload = (file, fileList, isAccepted, reasons) => {
    return new Promise(async (resolve, reject) => {
      isLogin();
      console.log('before upload');
      const imageBase64 = await FileToBase64(file);
      if (!file) {
        Msg.error({
          message: '请选择文件'
        });
        return reject()
      };
      const ext = file.name.split('.').pop().toLowerCase();
      
      if (!acceptList.includes(ext)) {
        Msg.error({
          message: '文件格式不正确',
        });
        return reject()
      }
      if (imageBase64?.length > maxLength * 1024 * 1024) {
        Msg.error({
          message: `文件大小不能超过${maxLength}M`
        });
        return reject()
      }
      if(ext === 'pdf'){
        const res = await baseOcrRequest({
          cmd:'ConvertPDFToImage', 
          data:{
            PDFBase64:imageBase64
          },
        })
        if(!res.error){
          let _url = imageBase64;
          onSuccess && onSuccess({
            img:{
              type: 'base64',
              previewUrl: res.ImageUrls[0],
              url: _url,
              base64prefix: '',
              prefix: `${prefix}Base64`,
              isExample: false,
              fileType: ext,
              cantPreview: false,
              pdfImgList:res.ImageUrls
            },
            type:ext,
          });
        }else{
          Msg.error({
            message: res.message
          });
          return reject();
        }
      }else{
        let _url = imageBase64;
        if (prefix === 'OfdFile') {
          _url = imageBase64.replace('data:application/octet-stream;base64,', '')
        } else if (ext === 'bmp') {
          _url = imageBase64.replace('data:image/bmp;base64,', '')
        }

        onSuccess && onSuccess({
          img:{
            type: 'base64',
            previewUrl: imageBase64,
            url: _url,
            base64prefix: '',
            prefix: `${prefix}Base64`,
            isExample: false,
            fileType: ext,
            cantPreview: false
          },
          type:ext,
        });
      }
      
      return reject()
    })
  };

  const handleStart = (file, context) => {
    setStatus('validating');
    setProgress(null);
    setFile(file);
  };

  const handleProgress = (progress, context) => {
    if (progress !== null) {
      setProgress(progress);
    }
  };

  const handleSuccess = (result, context) => {
    setStatus('success');
  };

  const handleError = (error, context) => {
    setStatus('error');
  };

  return (
    <div className="ocr_upload__container">
      <div className="ocr_upload__width"
      >
        <Upload
          beforeUpload={beforeUpload}
          onStart={handleStart}
          onProgress={handleProgress}
          onSuccess={handleSuccess}
          onError={handleError}
        >
          <Tooltip title={`支持${acceptList.join('、')}等格式，大小不超过7M`}>
          <Button
            style={{ fontWeight: 600,borderRadius: 3 }}
            ref={clickBtn}
            onClick={async (event) => {
              console.log('dd')
              await isLogin(event);
            }}
            // type="hole"
            width="full"
          >
            上传本地文件
          </Button>
          </Tooltip>
        </Upload>
        <div className="unv-upload__btn-extra">
          <span className="unv-upload__btn-extra__tips">
            或
          </span>
          <Input style={{width: "100%"}} value={imgUrl} onChange={(e) => { setImgUrl(e.target.value) }} placeholder={`输入在线图片或PDF的URL,支持${acceptList.join('、')}等格式，大小不超过7M`} />
          <Button className="upload_btn" onClick={async () => {
            let ext = getFileType(imgUrl,acceptList) ;
            console.log('ext',ext);
            if (!URL_EXP.test(imgUrl)) {
              Msg.error({
                message: '请输入正确的url地址'
              });
            } else if (acceptList.filter((i) => { return ext === (i).toLowerCase() }).length <= 0) {
              Msg.error({
                message: '文件格式不正确'
              });
            } else {
              if(ext === 'pdf'){
                const res = await baseOcrRequest({
                  cmd:'ConvertPDFToImage', 
                  data:{
                    PDFUrl:imgUrl
                  },
                })
                console.log('conver',res)
                if(!res.error){
                  onSuccess && onSuccess({
                    img:{
                      type: 'url',
                      previewUrl: res.ImageUrls[0],
                      url: imgUrl,
                      prefix: `${prefix}Url`,
                      fileType: ext,
                      isExample: false,
                      cantPreview: false,
                      pdfImgList:res.ImageUrls
                    },
                    type: ext,
                  });
                }else{
                  Msg.error({
                    message: res.message
                  });
                }
              }else{
                onSuccess && onSuccess({
                  img:{
                    type: 'url',
                    url: imgUrl,
                    prefix: `${prefix}Url`,
                    fileType: ext,
                    isExample: false,
                    cantPreview: false
                  },
                  type: ext,
                });
              }
              
              setImgUrl('');
              setVisible(false);
            }

          }}>
            <Icon name="search"></Icon></Button>
        </div>
      </div>
    </div>

  );
};