import http from './http.js';
const baseUrl = 'https://aistudio.cloud.tencent.com/' ;
const defaultUrl = 'https://aistudio.cloud.tencent.com/demo-user/capi/getResult/' ;
// ocr 文字识别
export async function baseOcrRequest({cmd,data,isExample = false,fileType,module}) {
  let url = isExample ?  'demo-common/democase/getDemoResultByDefaultImage' : 'demo-user/capi/getResult/';
  let payload = {
    ...data
  }
  if(cmd === 'ReconstructDocument' ){
    payload.Config = {
      EnableInsetImage: data.EnableInsetImage === '1' ? true : false
    };
    delete payload.EnableInsetImage;
  }else if(cmd === 'ReconstructDocumentDemo' ){
    payload.FileType = fileType.toUpperCase()
  }

  return http.post({
    url: `${baseUrl}${url}`,
    cmd,
    data:payload,
    module
  });
}
export async function chartOcrRequest(data,isExample = true) {
  console.log('isExample',isExample)
  let url = isExample ?  'demo-common/democase/getDemoResultByDefaultImage' : 'demo-user/capi/getResult/'
  console.log('chartOcrRequest',data)
  return http.post({
    url: `${baseUrl}${url}`,
    cmd:'ChatOCR',
    data,
  });
}
export async function chartOcrFeedBackRequest(data) {
  console.log('chartOcrRequest',data)
  return http.post({
    url: defaultUrl,
    cmd:'ChatOCRDemoFeedBack',
    data,
  });
}
export  async function getProductList(){
  const url = 'demo-common/getProductConfig';
  return http.demoPost({
    url: `${baseUrl}${url}`
  });
}