import React, { useState,useEffect } from "react";
import {  Radio, Select, Button, Input } from "@tencent/universe-ui/build/lib";
const { InputNumber } = Input;



function Params({params,value ,onChange,showClose = false, placeholder="请输入" ,onClose}) {

  const tem = () => {
    let paramsCon = <></>;
    switch (params.type) {
      case 'select':
        paramsCon = <div className="flex">
          
          <div style={{maxWidth: '100%'}}>
              <Select
                status={params.status}
                options={params.options}
                placeholder='请选择'
                value={value}
                onChange={(value) => {
                  let realVal = params.options[params.options.findIndex(i => i.value === value)]?.realValue || value;
                  console.log('Select value',params.options,realVal)
                  onChange && onChange(value,realVal);
                }}
              />
          </div>
          {
            showClose && <Button onClick={onClose} type="link-weak" className="ml-10">
              <i className="universe-icon univ-icon-close"></i>
            </Button>
          }
          
          
        </div>
        break;
      case 'mulSelect':
          paramsCon = <div className="flex">
            <div tyle={{maxWidth: '100%'}}>
            <Select
              options={params.options}
              placeholder='请选择'
              value={value === [] ? '' : value?.join('')}
              onChange={(value) => {
                onChange && onChange(value ? [Number(value)] : []);
              }}
            />
            </div>
            
             {
              showClose && <Button onClick={onClose} type="link-weak" className="ml-10">
                  <i className="universe-icon univ-icon-close"></i>
                </Button>
              }
          </div>
          break;
      case 'radio':
        paramsCon = <>
          <Radio.Group value={value} onChange={(value) => {
             onChange && onChange(value);
          }} className="unv-radio__group unv-radio__group--grid unv-radio__group--grid-3">
            {
              params.options.map((op, index) => (
                <Radio key={index} name={op.value}>{op.text}</Radio>
              ))
            }
          </Radio.Group>
        </>
        break;
      case 'input':
        paramsCon = <div className="flex">
          <Input 
            status={params.status}
            value={value} onChange={(e) => {
              onChange && onChange(e.target.value);
            }} 
            placeholder={placeholder}/> 
            {
              showClose && <Button onClick={onClose} type="link-weak" className="ml-10">
                  <i className="universe-icon univ-icon-close"></i>
                </Button>
              }
          </div>
        break;
        case 'input-number':
          paramsCon = <div className="flex">
            <InputNumber 
              value={value} 
              min={params.min}
              max={params.max}
              onStep={(value) => {
                onChange && onChange(value);
              }} 
              onChange={(value) => {
                onChange && onChange(value);
              }} />
            </div>
          break;
    }
    return paramsCon
  }
  return (
    <>
    {tem(params)}
    </>
  );
}

export default Params;
