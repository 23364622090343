
export const navList = [
  { text: '体验中心', key: '1', href: "", isActive: true, hasSub: false },
  { text: '产品控制台', key: '2', href: "", isActive: false, hasSub: false },
  { text: '产品文档', key: '3', href: "", isActive: false, hasSub: false },
]
export const OcrConsoleUrl = 'https://console.cloud.tencent.com/ocr';

export const navItemsTest = [
  [
    {
      "title": "大模型知识引擎",
      "icon": "ai-demo-icon-32-ocr-text",
      "children": [
        {
          "title": "文档解析",
          "preview": {
            "showRect": true,
            "PolygonList": "DocumentRecognizeInfo"
          },
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "prefix": "File",
          "paramsList": [
            {
              "key": "FileType",
              "label": "文件类型",
              "default": "Image",
              "type": "select",
              "options": [
                {
                  "value": "Image",
                  "text": "图片"
                },
                {
                  "value": "PDF",
                  "text": "PDF"
                }
              ]
            },
            {
              "key": "FileStartPageNumber",
              "label": "pdf识别的起始页码",
              "default": 1,
              "type": "input-number"
            },
            {
              "key": "FileEndPageNumber",
              "label": "pdf识别的结束页码",
              "default": 1,
              "min": "FileStartPageNumber",
              "max": "FileStartPageNumber",
              "type": "input-number"
            }
          ],
          "text": "提供高效的文档解析功能，将图片或PDF文件转换成Markdown格式文件，包括表格、公式、图片和文本等元素的解析，并将内容智能转换成阅读顺序，可将文档内容转换成易于阅读的格式。",
          "action": "ReconstructDocumentDemo",
          "module": "lkeap",
          "url": "https://cloud.tencent.com/document/product/1759/107504",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/ReconstructDocument/tu2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/ReconstructDocument/tu3.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/ReconstructDocument/tu4.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/ReconstructDocument/tu7.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/ReconstructDocument/tu12.jpg"
          ],
          "resultConfig": {
            "type": "markdown",
            "firstField": "TextDetections",
            "secondField": "DetectedText"
          },
          "rectConfig": {
            "positionType": 4,
            "firstField": "DocumentRecognizeInfo",
            "secondField": "Elements"
          }
        }
      ]
    },
    {
      "title": "通用文字识别",
      "icon": "ai-demo-icon-32-ocr-text",
      "children": [
        {
          "title": "商户照片分类",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "paramsList": [],
          "text": "用于识别门头照分类标签信息",
          "action": "ClassifyStoreName",
          "url": "https://cloud.tencent.com/document/api/866/110001",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/RecognizeStore/ClassifyStore1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/RecognizeStore/ClassifyStore2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/RecognizeStore/ClassifyStore3.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/RecognizeStore/ClassifyStore4.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/RecognizeStore/ClassifyStore5.jpg"
          ],
          "resultConfig": {
            "type": "store",
            "firstField": "StoreLabel"
          }
        },
        {
          "title": "商户门头照识别",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "paramsList": [],
          "text": "识别门头照文字识别结果以及对应分类标签信息",
          "action": "RecognizeStoreName",
          "url": "https://cloud.tencent.com/document/api/866/110000",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/RecognizeStore/RecognizeStore1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/RecognizeStore/RecognizeStore2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/RecognizeStore/RecognizeStore3.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/RecognizeStore/RecognizeStore4.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/RecognizeStore/RecognizeStore5.jpg"
          ],
          "resultConfig": {
            "type": "store",
            "firstField": "StoreInfo"
          },
          "rectConfig": {
            "positionType": "1", //  1:x y height width
            "firstField": "StoreInfo",
            "secondField": "Rect",
            "key":"Value"
          }
        },
        {
          "title": "通用文本图像告警",
          "preview": {
            "showRect": true,
            "firstField": "Polygon",
            "PolygonList": [
              "Copy",
              "Reprint",
              "Blur",
              "Reflection",
              "BorderIncomplete"
            ]
          },
          "acceptList": [
            "bmp",
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "paramsList": [
            {
              "isHide": true,
              "key": "EnablePdf",
              "label": "识别语言",
              "default": true,
              "type": "input"
            },
            {
              "key": "Type",
              "label": "配置模版",
              "default": "General",
              "type": "select",
              "options": [
                {
                  "value": "General",
                  "text": "通用告警"
                },
                {
                  "value": "LicensePlate",
                  "text": "车牌告警"
                }
              ]
            },
            {
              "key": "PdfPageNumber",
              "label": "选择PDF页",
              "default": 1,
              "type": "input-number"
            }
          ],
          "text": "支持多种类型证件有效性检测告警，包括卡证复印件告警、卡证翻拍告警等功能。可以应用于各种证件信息有效性校验场景，例如银行开户、用户注册等场景。",
          "action": "RecognizeGeneralTextImageWarn",
          "url": "https://cloud.tencent.com/document/product/866/105752",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/RecognizeGeneralTextImageWarn/RecognizeGeneralTextImageWarn1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/RecognizeGeneralTextImageWarn/RecognizeGeneralTextImageWarn2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/RecognizeGeneralTextImageWarn/RecognizeGeneralTextImageWarn3.png"
          ],
          "resultConfig": {
            "type": "warn",
            "secondField": "SpecificMatter",
            "warnsList": {
              "Copy": "复印告警",
              "Reprint": "翻拍告警",
              "Blur": "模糊告警",
              "Reflection": "反光告警",
              "BorderIncomplete": "边框不完整告警"
            }
          },
          "rectConfig": {
            "positionType": "3", //  1:x y height width 2: 四点坐标
            "firstField": "",
            "secondField": "Polygon",
          }
        },
        {
          "title": "通用印刷体识别",
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "paramsList": [
            {
              "isHide": true,
              "key": "IsPdf",
              "label": "识别语言",
              "default": true,
              "type": "input"
            },
            {
              "key": "LanguageType",
              "label": "识别语言",
              "default": "zh",
              "type": "select",
              "options": [
                {
                  "value": "zh",
                  "text": "中英文混合"
                },
                {
                  "value": "auto",
                  "text": "自动识别"
                },
                {
                  "value": "zh_rare",
                  "text": "中文生僻字"
                },
                {
                  "value": "jap",
                  "text": "日语"
                },
                {
                  "value": "kor",
                  "text": "韩语"
                },
                {
                  "value": "spa",
                  "text": "西班牙语"
                },
                {
                  "value": "fre",
                  "text": "法语"
                },
                {
                  "value": "ger",
                  "text": "德语"
                },
                {
                  "value": "por",
                  "text": "葡萄牙语"
                },
                {
                  "value": "vie",
                  "text": "越南语"
                },
                {
                  "value": "may",
                  "text": "马来语"
                },
                {
                  "value": "rus",
                  "text": "俄语"
                },
                {
                  "value": "ita",
                  "text": "意大利语"
                },
                {
                  "value": "hol",
                  "text": "荷兰语"
                },
                {
                  "value": "swe",
                  "text": "瑞典语"
                },
                {
                  "value": "fin",
                  "text": "芬兰语"
                },
                {
                  "value": "dan",
                  "text": "丹麦语"
                },
                {
                  "value": "nor",
                  "text": "挪威语"
                },
                {
                  "value": "hun",
                  "text": "匈牙利语"
                },
                {
                  "value": "tha",
                  "text": "泰语"
                },
                {
                  "value": "lat",
                  "text": "拉丁语系"
                },
                {
                  "value": "ara",
                  "text": "阿拉伯语"
                }
              ]
            }
          ],
          "text": "支持多场景、任意版面下整图文字的识别，包括中英文、字母、数字和日语、韩语、西班牙语等二十余种多语言识别。",
          "action": "GeneralBasicOCR",
          "url": "https://cloud.tencent.com/document/product/866/33526",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/GeneralBasicOCR/GeneralBasicOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/GeneralBasicOCR/GeneralBasicOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/GeneralBasicOCR/GeneralBasicOCR3.jpg"
          ],
          "resultConfig": {
            "type": "list",
            "firstField": "TextDetections",
            "secondField": "DetectedText"
          },
          "rectConfig": {
            "positionType": "1", //  1:x y height width
            "firstField": "TextDetections",
            "secondField": "ItemPolygon",
            "key":"DetectedText"
          }
        },
        {
          "title": "通用印刷体识别（高精度版）",
          "text": "支持图像整体文字的检测和识别，返回文字框位置与文字内容。相比通用印刷体识别接口，准确率和召回率更高。",
          "action": "GeneralAccurateOCR",
          "paramsList": [
            {
              "isHide": true,
              "key": "IsPdf",
              "label": "识别语言",
              "default": true,
              "type": "input"
            }
          ],
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "url": "https://cloud.tencent.com/document/product/866/34937",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/GeneralAccurateOCR/GeneralAccurateOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/GeneralAccurateOCR/GeneralAccurateOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/GeneralAccurateOCR/GeneralAccurateOCR3.jpg"
          ],
          "resultConfig": {
            "type": "list",
            "firstField": "TextDetections",
            "secondField": "DetectedText"
          },
          "rectConfig": {
            "positionType": "1", //  1:x y height width
            "firstField": "TextDetections",
            "secondField": "ItemPolygon",
            "key":"DetectedText"
          }
        },
        {
          "title": "通用手写体识别",
          "text": "支持图片内手写体文字的检测和识别，针对手写字体无规则、字迹潦草、模糊等特点进行了识别能力的增强。",
          "action": "GeneralHandwritingOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/36212",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/GeneralHandwritingOCR/GeneralHandwritingOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/GeneralHandwritingOCR/GeneralHandwritingOCR2.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/GeneralHandwritingOCR/GeneralHandwritingOCR3.jpg"
          ],
          "resultConfig": {
            "type": "list",
            "firstField": "TextDetections",
            "secondField": "DetectedText"
          },
          "rectConfig": {
            "positionType": "2", //  1:x y height width 2: 四点坐标
            "firstField": "TextDetections",
            "secondField": "Polygon",
            "key":"DetectedText"
          }
        },
        {
          "title": "英文识别",
          "text": "支持图像英文文字的检测和识别，返回文字框位置与文字内容。支持多场景、任意版面下的英文、字母、数字和常见字符的识别，同时覆盖英文印刷体和英文手写体识别。",
          "action": "EnglishOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/34938",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/EnglishOCR/EnglishOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/EnglishOCR/EnglishOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/EnglishOCR/EnglishOCR3.jpg"
          ],
          "resultConfig": {
            "type": "list",
            "firstField": "TextDetections",
            "secondField": "DetectedText"
          },
          "rectConfig": {
            "positionType": "2", //  1:x y height width 2: 四点坐标
            "firstField": "TextDetections",
            "key":"DetectedText",
            "secondField": "Polygon",
          }
        },
        {
          "title": "广告文字识别",
          "text": "支持广告商品图片内文字的检测和识别，返回文本框位置与文字内容。支持中英文、横排和竖排文字识别，支持90度、180度、270度翻转以及倾斜场景文字识别。",
          "action": "AdvertiseOCR",
          "url": "https://cloud.tencent.com/document/product/866/49524",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/AdvertiseOCR/AdvertiseOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/AdvertiseOCR/AdvertiseOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/AdvertiseOCR/AdvertiseOCR3.jpg"
          ],
          "resultConfig": {
            "type": "list",
            "firstField": "TextDetections",
            "secondField": "DetectedText"
          },
          "rectConfig": {
            "positionType": "2", //  1:x y height width 2: 四点坐标
            "firstField": "TextDetections",
            "secondField": "Polygon",
            "key":"DetectedText"
          }
        },
        {
          "title": "表格识别v2",
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "paramsList": [
            {
              "isHide": true,
              "key": "IsPdf",
              "label": "识别语言",
              "default": true,
              "type": "input"
            },
            {
              "isHide": true,
              "key": "PdfPageNumber",
              "label": "识别语言",
              "default": 1,
              "type": "input"
            },
            {
              "key": "TableLanguage",
              "label": "识别语言",
              "default": "zh",
              "type": "select",
              "options": [
                {
                  "value": "zh",
                  "text": "中英文"
                },
                {
                  "value": "jap",
                  "text": "日文"
                }
              ]
            }
          ],
          "text": "支持中英文图片/ PDF内常规表格、无线表格、多表格的检测和识别，支持日文有线表格识别，返回每个单元格的文字内容，支持旋转的表格图片识别，且支持将识别结果保存为 Excel 格式。",
          "action": "RecognizeTableOCR",
          "url": "https://cloud.tencent.com/document/product/866/49525",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/TableOCR/TableOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/TableOCR/TableOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/TableOCR/TableOCR3.jpg"
          ],
          "resultConfig": {
            "type": "table",
            "showDownBtn": "true"
          }
        },
        {
          "title": "表格识别v3",
          "languageValue": "TableLanguage",
          "pdf_file": true,
          "languageOptions": [
            [
              "zh",
              "中英文"
            ]
          ],
          "text": "支持中英文图片/PDF内常规表格、无线表格、多表格的检测和识别，返回每个单元格的文字内容，支持旋转的表格图片识别，且支持将识别结果保存为 Excel 格式。识别效果比表格识别V2更好，覆盖场景更加广泛，对表格难例场景，如无线表格、嵌套表格（有线表格中包含无线表格）的识别效果均优于表格识别V2，同时能避免部分印章干扰、表格线断裂等影响因素。",
          "action": "RecognizeTableAccurateOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "url": "https://cloud.tencent.com/document/product/866/86721",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/TableOCRDDS/TableOCRDDS1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/TableOCRDDS/TableOCRDDS2.png"
          ],
          "resultConfig": {
            "type": "table",
            "showDownBtn": "true"
          }
        }
      ]
    },
    {
      "title": "智能结构化",
      "icon": "ai-demo-icon-32-ocr-scanCode",
      "children": [
        {
          "title": "智能结构化",
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "paramsList": [
            {
              "isHide": true,
              "key": "IsPdf",
              "label": "识别语言",
              "default": true,
              "type": "input"
            },
            {
              "key": "ConfigId",
              "label": "配置模版",
              "default": "General",
              "type": "select",
              "options": [
                {
                  "value": "General",
                  "text": "通用场景"
                },
                {
                  "value": "OnlineTaxiItinerary",
                  "text": "网约车行程单"
                },
                {
                  "value": "RideHailingDriverLicense",
                  "text": "网约车驾驶证"
                },
                {
                  "value": "RideHailingTransportLicense",
                  "text": "网约车运输证"
                },
                {
                  "value": "WayBill",
                  "text": "快递运单"
                },
                {
                  "value": "AccountOpeningPermit",
                  "text": "银行开户许可证"
                },
                {
                  "value": "InvoiceEng",
                  "text": "海外发票模版"
                },
                {
                  "value": "Coin",
                  "text": "钱币识别模板"
                },
                {
                  "value": "OnboardingDocuments",
                  "text": "入职材料识别"
                },
                {
                  "value": "PropertyOwnershipCertificate",
                  "text": "房产证识别"
                },
                {
                  "value": "RealEstateCertificate",
                  "text": "不动产权证识别"
                },
                {
                  "value": "HouseEncumbranceCertificate",
                  "text": "他权证识别"
                },
                {
                  "value": "CarInsurance",
                  "text": "车险保单"
                }
              ]
            }
          ],
          "text": "支持智能及自定义的方式提取图片中的字段信息，无需训练灵活配置。适用于各类证照、单据、合同等版式中的结构化信息录入场景。",
          "action": "SmartStructuralOCRV2",
          "url": " https://cloud.tencent.com/document/product/1341/71470",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/SmartStructuralOCR/SmartStructuralOCR4.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/SmartStructuralOCR/SmartStructuralOCR5.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/SmartStructuralOCR/SmartStructuralOCR6.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/SmartStructuralOCR/SmartStructuralOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/SmartStructuralOCR/SmartStructuralOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/SmartStructuralOCR/SmartStructuralOCR3.jpg"
          ],
          "resultConfig": {
            "type": "smartList"
          },
          "options": [
            {
              "text": "标题",
              "value": "1"
            },
            {
              "text": "日期",
              "value": "2"
            },
            {
              "text": "金额",
              "value": "3"
            },
            {
              "text": "号码",
              "value": "4"
            },
            {
              "text": "证字号",
              "value": "5"
            },
            {
              "text": "机构",
              "value": "6"
            },
            {
              "text": "姓名",
              "value": "7"
            },
            {
              "text": "商品名称",
              "value": "8"
            },
            {
              "text": "性别",
              "value": "9"
            },
            {
              "text": "地址",
              "value": "10"
            },
            {
              "text": "company",
              "value": "11"
            },
            {
              "text": "数量",
              "value": "12"
            },
            {
              "text": "规格",
              "value": "13"
            }
          ]
        },
        {
          "title": "智能结构化(高级版)",
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "paramsList": [
            {
              "key": "ConfigId",
              "label": "配置模版",
              "default": "General",
              "type": "select",
              "options": [
                {
                  "value": "InvoiceEng1",
                  "text": "海运提单",
                  "realValue": "InvoiceEng"
                },
                {
                  "value": "InvoiceEng2",
                  "text": "国际invoice",
                  "realValue": "InvoiceEng"
                },
                {
                  "value": "WayBillEng",
                  "text": "海运订单",
                  "realValue": "WayBillEng"
                },
                {
                  "value": "General1",
                  "text": "磅单",
                  "realValue": "General"
                },
                {
                  "value": "General2",
                  "text": "进出口报关单",
                  "realValue": "General"
                }
              ]
            }
          ],
          "text": "支持智能及自定义的方式提取图片中的字段信息，无需训练灵活配置。适用于各类证照、单据、合同等版式中的结构化信息录入场景。",
          "action": "SmartStructuralPro",
          "url": " https://cloud.tencent.com/document/product/1341/71470",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/SmartStructuralOCR/SmartStructuralPro1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/SmartStructuralOCR/SmartStructuralPr2.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/SmartStructuralOCR/SmartStructuralPro3.png"
          ],
          "resultConfig": {
            "type": "smartList"
          },
          "options": [
            {
              "text": "标题",
              "value": "1"
            },
            {
              "text": "日期",
              "value": "2"
            },
            {
              "text": "金额",
              "value": "3"
            },
            {
              "text": "号码",
              "value": "4"
            },
            {
              "text": "证字号",
              "value": "5"
            },
            {
              "text": "机构",
              "value": "6"
            },
            {
              "text": "姓名",
              "value": "7"
            },
            {
              "text": "商品名称",
              "value": "8"
            },
            {
              "text": "性别",
              "value": "9"
            },
            {
              "text": "地址",
              "value": "10"
            },
            {
              "text": "company",
              "value": "11"
            },
            {
              "text": "数量",
              "value": "12"
            },
            {
              "text": "规格",
              "value": "13"
            }
          ]
        }
      ]
    }
  ],
  [
    {
      "title": "卡证文字识别",
      "icon": "ai-demo-icon-32-ocr-id",
      "children": [
        {
          "title": "身份证识别",
          "text": "支持二代身份证人像面所有字段的识别，包括姓名、性别、民族、出生日期、住址、公民身份证号。",
          "action": "IDCardOCR",
          "paramsList": [
            {
              "isHide": true,
              "key": "Config",
              "label": "识别语言",
              "default": "{\"CopyWarn\":\"true\",\"BorderCheckWarn\":\"true\",\"ReshootWarn\":\"true\",\"DetectPsWarn\":\"true\",\"TempIdWarn\":\"true\",\"InvalidDateWarn\":\"true\",\"Quality\":\"true\",\"ReflectWarn\":\"true\"}",
              "type": "input"
            },
            {
              "isHide": true,
              "key": "EnableReflectDetail",
              "label": "识别语言",
              "default": true,
              "type": "input"
            },
            {
              "key": "CardSide",
              "label": "正反面",
              "default": "FRONT",
              "type": "radio",
              "options": [
                {
                  "value": "FRONT",
                  "text": "人像面"
                },
                {
                  "value": "BACK",
                  "text": "国徽面"
                }
              ]
            }
          ],
          "url": "https://cloud.tencent.com/document/product/866/33524",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/IDCardOCR/IDCardOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/IDCardOCR/IDCardOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/IDCardOCR/IDCardOCR3.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/IDCardBackOCR/IDCardBackOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/IDCardBackOCR/IDCardBackOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/IDCardBackOCR/IDCardBackOCR3.jpg"
          ],
          "resultConfig": {
            "type": "map",
            "fieldsMap": {
              "Name": "姓名",
              "Sex": "性别",
              "Nation": "民族",
              "Birth": "出生",
              "Address": "住址",
              "IdNum": "公民身份号码",
              "Authority": "发证机关",
              "ValidDate": "证件有效期限"
            }
          }
        },
        {
          "title": "银行卡识别",
          "text": "支持对中国大陆主流银行卡的卡号、银行信息、有效期等关键字段的检测与识别。",
          "action": "BankCardOCR",
          "url": "https://cloud.tencent.com/document/product/866/36216",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/BankCardOCR/BankCardOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/BankCardOCR/BankCardOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/BankCardOCR/BankCardOCR3.jpg"
          ],
          "resultConfig": {
            "type": "map",
            "fieldsMap": {
              "BankInfo": "银行",
              "CardType": "银行卡类型",
              "CardName": "银行卡名称",
              "CardNo": "银行卡号",
              "ValidDate": "有效期限"
            }
          }
        },
        {
          "title": "护照识别（中国大陆地区护照）",
          "beta": false,
          "text": "支持中国大陆护照个人资料页多个字段的检测与识别。",
          "action": "PassportOCR",
          "url": "https://cloud.tencent.com/document/product/866/37840",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/PassportOCR/PassportOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/PassportOCR/PassportOCR2.png"
          ],
          "resultConfig": {
            "type": "map",
            "fieldsMap": {
              "Country": "国家码",
              "PassportNo": "护照号",
              "Sex": "性别",
              "Nationality": "国籍",
              "BirthDate": "出生日期",
              "BirthPlace": "出生地点",
              "IssueDate": "签发日期",
              "ExpiryDate": "有效期",
              "Signature": "持证人签名",
              "CodeSet": "最下方第一行 MRZ Code 序列",
              "CodeCrc": "最下方第二行 MRZ Code 序列",
              "Name": "姓名",
              "FamilyName": "姓",
              "FirstName": "名"
            }
          }
        },
        {
          "title": "护照识别（港澳台地区及境外护照）",
          "beta": false,
          "text": "支持中国港澳台地区以及其他国家、地区的护照。",
          "action": "MLIDPassportOCR",
          "url": "https://cloud.tencent.com/document/product/866/37657",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/MLIDPassportOCR/MLIDPassportOCR1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/MLIDPassportOCR/MLIDPassportOCR3.png"
          ],
          "resultConfig": {
            "type": "map",
            "fieldsMap": {
              "ID": "护照ID",
              "Name": "姓名",
              "DateOfBirth": "出生日期",
              "Sex": "性别（F女，M男）",
              "DateOfExpiration": "有效期",
              "IssuingCountry": "发行国",
              "Nationality": "国籍"
            }
          }
        },
        {
          "title": "港澳台通行证识别",
          "beta": false,
          "text": "支持对卡式港澳台通行证的识别，包括签发地点、签发机关、有效期限、性别、出生日期、英文姓名、姓名、证件号等字段。",
          "action": "PermitOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/37074",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/PermitOCR/PermitOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/PermitOCR/PermitOCR2.jpg"
          ],
          "resultConfig": {
            "type": "map",
            "fieldsMap": {
              "Name": "姓名",
              "EnglishName": "英文姓名",
              "Number": "证件号",
              "Sex": "性别",
              "ValidDate": "有效期限",
              "IssueAuthority": "签发机关",
              "IssueAddress": "签发地点",
              "Birthday": "出生日期"
            }
          }
        },
        {
          "title": "港澳台来往内地通行证识别",
          "beta": false,
          "text": "支持港澳居民来往内地通行证及台湾居民来往大陆通行证识别，包含中英文姓名、出生日期、性别、有效期限、签发机关、证件号码、换证次数、证件类型。",
          "action": "MainlandPermitOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/43105",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/MainlandPermitOCR/MainlandPermitOCR1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/MainlandPermitOCR/MainlandPermitOCR2.png"
          ],
          "resultConfig": {
            "type": "map",
            "fieldsMap": {
              "Name": "中文姓名",
              "EnglishName": "英文姓名",
              "Sex": "性别",
              "Birthday": "出生日期",
              "IssueAuthority": "签发机关",
              "ValidDate": "有效期限",
              "Number": "证件号",
              "IssueAddress": "签发地点",
              "IssueNumber": "签发次数",
              "Type": "证件类别"
            }
          }
        },
        {
          "title": "港澳台居住证识别",
          "beta": false,
          "text": "支持港澳台居住证的正面及反面的关键字段识别，包含姓名、性别、出生、地址、身份证号、签发机关、有效期限。",
          "action": "HmtResidentPermitOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/43106",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/HmtResidentPermitOCR/HmtResidentPermitOCR1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/HmtResidentPermitOCR/HmtResidentPermitOCR2.png"
          ],
          "resultConfig": {
            "type": "map",
            "fieldsMap": {
              "Name": "证件姓名",
              "Sex": "性别",
              "Birth": "出生日期",
              "Address": "地址",
              "IdCardNo": "身份证号",
              "Authority": "签发机关",
              "ValidDate": "证件有效期限",
              "PassNo": "通行证号码"
            }
          }
        },
        {
          "title": "驾驶证识别",
          "text": "支持对驾驶证主页所有字段的自动定位与识别，包含证号、姓名、性别、国籍、住址、出生日期、初次领证日期、准驾车型、有效期限、发证单位。",
          "action": "DriverLicenseOCR",
          "paramsList": [
            {
              "key": "CardSide",
              "label": "主副页",
              "default": "FRONT",
              "type": "radio",
              "options": [
                {
                  "value": "FRONT",
                  "text": "主页"
                },
                {
                  "value": "BACK",
                  "text": "副页"
                }
              ]
            }
          ],
          "languageValue": "CardSide",
          "languageValueText": "识别卡面",
          "url": "https://cloud.tencent.com/document/product/866/36213",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/DriverLicenseOCR/DriverLicenseOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/DriverLicenseOCR/DriverLicenseOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/DriverLicenseOCR/DriverLicenseOCR3.jpg"
          ],
          "resultConfig": {
            "type": "map",
            "fieldsMap": {
              "Name": "姓名",
              "Sex": "性别",
              "Nationality": "国籍",
              "Address": "住址",
              "DateOfBirth": "出生日期",
              "DateOfFirstIssue": "初次领证日期",
              "Class": "准驾车型",
              "StartDate": "有效期开始时间",
              "EndDate": "有效期截止时间",
              "CardCode": "证号",
              "ArchivesCode": "档案编号",
              "Record": "记录",
              "IssuingAuthority": "发证单位",
              "State": "状态",
              "CumulativeScore": "累积记分"
            }
          }
        },
        {
          "title": "行驶证识别",
          "text": "支持行驶证主页所有字段的自动定位与识别，包含号牌号码、车辆类型、所有人、住址、使用性质、品牌型号、车辆识别代码、发动机号码、注册日期、发证日期、印章。",
          "action": "VehicleLicenseOCR",
          "url": "https://cloud.tencent.com/document/product/866/36209",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/VehicleLicenseOCR/VehicleLicenseOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/VehicleLicenseOCR/VehicleLicenseOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/VehicleLicenseOCR/VehicleLicenseOCR3.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/VehicleLicenseBackOCR/VehicleLicenseBackOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/VehicleLicenseBackOCR/VehicleLicenseBackOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/VehicleLicenseBackOCR/VehicleLicenseBackOCR3.jpg"
          ],
          "paramsList": [
            {
              "key": "CardSide",
              "label": "主副页",
              "default": "FRONT",
              "type": "radio",
              "options": [
                {
                  "value": "FRONT",
                  "text": "主页",
                  "firstField": "FrontInfo"
                },
                {
                  "value": "BACK",
                  "text": "副页",
                  "firstField": "BackInfo"
                }
              ]
            }
          ],
          "resultConfig": {
            "type": "map",
            "firstField": [
              "FrontInfo",
              "BackInfo"
            ],
            "fieldsMap": {
              "PlateNo": "号牌号码",
              "VehicleType": "车辆类型",
              "Owner": "所有人",
              "Address": "住址",
              "UseCharacter": "使用性质",
              "Model": "品牌型号",
              "Seal": "印章信息",
              "Vin": "车辆识别代号",
              "EngineNo": "发动机号码",
              "RegisterDate": "注册日期",
              "IssueDate": "发证日期",
              "PlateNo": "号牌号码",
              "FileNo": "档案编号",
              "AllowNum": "核定载人数",
              "TotalMass": "总质量",
              "CurbWeight": "整备质量",
              "Record": "检验记录",
              "LoadQuality": "核定载质量",
              "ExternalSize": "外廓尺寸",
              "TotalQuasiMass": "准牵引总质量",
              "Marks": "备注"
            }
          }
        },
        {
          "title": "营业执照识别",
          "text": "支持快速精准识别营业执照上的字段，包括注册号、公司名称、经营场所、主体类型、法定代表人、注册资金、组成形式、成立日期、营业期限和经营范围等字段。",
          "action": "BizLicenseOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/36215",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/BizLicenseOCR/BizLicenseOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/BizLicenseOCR/BizLicenseOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/BizLicenseOCR/BizLicenseOCR3.jpg"
          ],
          "resultConfig": {
            "type": "map",
            "fieldsMap": {
              "RegNum": "注册号",
              "Name": "名称",
              "Type": "类型",
              "Address": "住所",
              "Person": "法定代表人",
              "Capital": "注册资本",
              "Period": "营业期限",
              "ComposingForm": "组成形式",
              "SetDate": "成立日期",
              "Business": "经营范围"
            }
          }
        },
        {
          "title": "名片识别",
          "text": "支持名片各字段的自动定位与识别，包含姓名、电话、手机号、邮箱、公司、部门、职位、网址、地址、QQ、微信、MSN等。",
          "action": "BusinessCardOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/36214",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/BusinessCardOCR/BusinessCardOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/BusinessCardOCR/BusinessCardOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/BusinessCardOCR/BusinessCardOCR3.jpg"
          ],
          "resultConfig": {
            "type": "keyMap",
            "firstField": "BusinessCardInfos"
          },
          // "rectConfig": {
          //   "positionType": "1", //  1:x y height width
          //   "firstField": "BusinessCardInfos",
          //   "secondField": "ItemCoord",
          //   "key":"Value"
          // }
        },
        {
          "title": "机动车登记证书识别",
          "beta": false,
          "text": "支持国内机动车登记证书主要字段的结构化识别，包括机动车所有人、身份证明名称、号码、车辆型号、车辆识别代号、发动机号、制造厂名称等。",
          "action": "VehicleRegCertOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/38297",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/VehicleRegCertOCR/VehicleRegCertOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/VehicleRegCertOCR/VehicleRegCertOCR2.jpg"
          ],
          "resultConfig": {
            "type": "keyMap",
            "firstField": "VehicleRegCertInfos"
          }
        },
        {
          "title": "智能卡证分类识别",
          "beta": false,
          "text": "支持身份证正反面、护照、名片、银行卡、行驶证主副页、港澳台居住证正反面等16种卡证的智能分类。",
          "action": "ClassifyDetectOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/46770",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/ClassifyDetectOCR/ClassifyDetectOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/ClassifyDetectOCR/ClassifyDetectOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/ClassifyDetectOCR/ClassifyDetectOCR3.jpg"
          ],
          "resultConfig": {
            "type": "list",
            "firstField": "ClassifyDetectInfos",
            "secondField": "Name"
          }
        },
        {
          "title": "外国人永久居留身份证识别",
          "beta": false,
          "tag": "公测体验",
          "icon": "ai-demo-icon-32-ocr-scanCode",
          "text": "可准确识别和结构化新版永居证的中英文姓名、性别等正反面关键字段，支持头像切边，头像面、国徽面自动分类，正反面混贴识别。同步支持旧版永居证识别，识别准确率高，服务稳定。",
          "action": "RecognizeForeignPermanentResidentIdCard",
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "paramsList": [
            {
              "isHide": true,
              "key": "EnablePdf",
              "label": "识别语言",
              "default": true,
              "type": "input"
            }
          ],
          "url": "https://cloud.tencent.com/document/product/866/103033",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/ForeignPermanentResidentIdCard/ForeignPermanentResidentIdCard1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/card/ForeignPermanentResidentIdCard/ForeignPermanentResidentIdCard2.jpg"
          ],
          "resultConfig": {
            "type": "map",
            "fieldsMap": {
              "CnName": "中文姓名",
              "EnName": "英文名",
              "Sex": "性别",
              "DateOfBirth": "出生日期",
              "Nationality": "国籍",
              "PeriodOfValidity": "有效期限",
              "No": "证件号码"
            }
          }
        }
      ]
    },
    {
      "title": "智能扫码",
      "icon": "ai-demo-icon-32-ocr-scanCode",
      "children": [
        {
          "title": "二维码和条形码识别",
          "text": "支持条形码和二维码的识别（包括 DataMatrix 和 PDF417）。",
          "action": "QrcodeOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/38292",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/QrcodeOCR/QrcodeOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/QrcodeOCR/QrcodeOCR2.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/general/QrcodeOCR/QrcodeOCR3.png"
          ],
          "resultConfig": {
            "type": "QrcodeOCR",
            "key":"Url"
          },
          "rectConfig": {
            "positionType": "2", //  1:x y height width 2: 四点坐标
            "firstField": "CodeResults",
            "secondField": "Position",
            "positionArgType":"object",
            "key":"Url"
          }
          // CodeResults Position
        }
      ]
    }
  ],
  [
    {
      "title": "票据单据识别",
      "icon": "ai-demo-icon-32-ocr-receipt",
      "children": [
        {
          "title": "通用票据识别（高级版）",
          "pdf_file": true,
          "beta": false,
          "text": "支持 单张、多张、多类型 票据的混合识别，同时支持自选需要识别的票据类型，已支持票种包括：增值税发票（专票、普票、卷票）、全电发票、非税发票、定额发票、通用机打发票、购车发票、火车票、出租车发票、机票行程单、汽车票、轮船票、过路过桥费发票共14种标准报销发票，并支持其他类发票的识别。",
          "action": "RecognizeGeneralInvoice",
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "url": "https://cloud.tencent.com/document/product/866/90802",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/MixedInvoiceDetect/MixedInvoiceDetect1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/MixedInvoiceDetect/MixedInvoiceDetect2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/DutyPaidProofOCR/DutyPaidProofOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/RecognizeMedicalInvoiceOCR/RecognizeMedicalInvoiceOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/RecognizeOnlineTaxiItineraryOCR/RecognizeOnlineTaxiItineraryOCR1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/TrainTicketOCR/TrainTicketOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/TrainTicketOCR/TrainTicketOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/TaxiInvoiceOCR/TaxiInvoiceOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/TaxiInvoiceOCR/TaxiInvoiceOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/BusInvoiceOCR/BusInvoiceOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/BusInvoiceOCR/BusInvoiceOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/ShipInvoiceOCR/ShipInvoiceOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/ShipInvoiceOCR/ShipInvoiceOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/FlightInvoiceOCR/FlightInvoiceOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/FlightInvoiceOCR/FlightInvoiceOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/TollInvoiceOCR/TollInvoiceOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/TollInvoiceOCR/TollInvoiceOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/VatRollInvoiceOCR/VatRollInvoiceOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/VatRollInvoiceOCR/VatRollInvoiceOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/QuotaInvoiceOCR/QuotaInvoiceOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/QuotaInvoiceOCR/QuotaInvoiceOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/InvoiceGeneralOCR/InvoiceGeneralOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/InvoiceGeneralOCR/InvoiceGeneralOCR2.jpg"
          ],
          "paramsList": [
            {
              "isHide": true,
              "key": "EnablePdf",
              "label": "识别语言",
              "default": true,
              "type": "input"
            },
            {
              "key": "Types",
              "label": "选择票种",
              "default": [],
              "max": 1,
              "type": "mulSelect",
              "options": [
                {
                  "value": "",
                  "text": "混合识别"
                },
                {
                  "value": "0",
                  "text": "出租车发票"
                },
                {
                  "value": "1",
                  "text": "定额发票"
                },
                {
                  "value": "2",
                  "text": "火车票"
                },
                {
                  "value": "3",
                  "text": "增值税发票"
                },
                {
                  "value": "5",
                  "text": "机票行程单"
                },
                {
                  "value": "8",
                  "text": "通用机打发票"
                },
                {
                  "value": "9",
                  "text": "汽车票"
                },
                {
                  "value": "10",
                  "text": "轮船票"
                },
                {
                  "value": "11",
                  "text": "增值税发票（卷票 ）"
                },
                {
                  "value": "12",
                  "text": "购车发票"
                },
                {
                  "value": "13",
                  "text": "过路过桥费发票"
                },
                {
                  "value": "15",
                  "text": "非税发票"
                },
                {
                  "value": "16",
                  "text": "全电发票"
                },
                {
                  "value": "17",
                  "text": "医疗发票"
                },
                {
                  "value": "-1",
                  "text": "其他发票"
                }
              ]
            }
          ],
          "resultConfig": {
            "type": "invoice",
            "firstField": "MixedInvoiceItems",
            "invoiceTypeMap": {
              "0": "出租车发票",
              "1": "定额发票",
              "2": "火车票",
              "3": "增值税发票",
              "5": "机票行程单",
              "8": "通用机打发票",
              "9": "汽车票",
              "10": "轮船票",
              "11": "增值税发票（卷票 ）",
              "12": "购车发票",
              "13": "过路过桥费发票",
              "15": "非税发票",
              "16": "全电发票",
              "17": "医疗发票",
              "-1": "其他发票"
            },
            "fieldsMap": {
              "CheckCode": "校验码",
              "FormType": "发票联次",
              "TravelTax": "车船税",
              "BuyerAddrTel": "购买方地址电话",
              "BuyerBankAccount": "购买方银行账号",
              "CompanySealContent": "公司印章内容",
              "ServiceName": "服务类型",
              "City": "市",
              "AcquisitionMark": "是否有收购标记（0：没有，1：有）",
              "BlockChainMark": "是否有区块链标记（0：没有，1：有）",
              "ElectronicFullMark": "是否有全电纸质票（0：没有，1：有）",
              "AgentMark": "是否有代开标记（0：没有，1：有）",
              "TransitMark": "是否有通行费标记",
              "OilMark": "是否有成品油标记",
              "Title": "发票名称",
              "Code": "发票代码",
              "Number": "发票号码",
              "NumberConfirm": "机打发票号码",
              "Date": "开票日期",
              "TotalCn": "价税合计（大写）",
              "PretaxAmount": "税前金额",
              "Tax": "合计税额",
              "MachineCode": "机器编号/机打发票代码",
              "Ciphertext": "密码区",
              "Remark": "备注",
              "SellerAddrTel": "销售方地址电话",
              "Buyer": "购买方名称",
              "BuyerTaxID": "购买方纳税人识别号",
              "CompanySealMark": "是否有公司印章（0：没有，1：有）",
              "Issuer": "开票人/填开单位",
              "Reviewer": "复核人",
              "Province": "省",
              "VatInvoiceItemInfo": "增值税发票项目信息",
              "CodeConfirm": "机打发票代码",
              "SubTotal": "小计金额",
              "SubTax": "小计税额",
              "VatElectronicItems": "电子发票详细条目信息",
              "QRCodeMark": "是否存在二维码（1：有，0：无）",
              "Time": "时间",
              "IndustryClass": "行业分类",
              "Kind": "消费类型",
              "ElectronicMark": "是否为浙江/广东通用机打发票（0：没有，1：有）",
              "Receiptor": "收款人",
              "SubType": "发票类型",
              "TypeDescription": "发票类型描述",
              "SubTypeDescription": "发票详细类型描述",
              "PaymentInfo": "经办人支付信息",
              "TicketPickupUser": "经办人取票用户",
              "MerchantNumber": "经办人商户号",
              "OrderNumber": "经办人订单号",
              "GeneralMachineItems": "条目",
              "TimeGetOn": "乘车时间",
              "DateGetOn": "乘车日期",
              "Total": "票价/价税合计（小写）/总金额",
              "UserName": "姓名",
              "UserID": "身份证号",
              "PlaceGetOn": "乘车地点",
              "TicketType": "客票类型",
              "VehicleType": "车型/车辆类型",
              "SeatNumber": "座位号",
              "CurrencyCode": "币种",
              "Entrance": "入口",
              "Exit": "出口",
              "HighwayMark": "高速标志（0：没有，1：有）",
              "OtherInvoiceListItems": "列表",
              "OtherInvoiceTableItems": "表格",
              "SellerTel": "销售方电话",
              "SellerAddress": "销售方地址",
              "SellerBank": "销售方开户行",
              "SellerBankAccount": "销售方银行账号",
              "BuyerID": "购买方身份证号码/组织机构代码",
              "TaxAuthorities": "主管税务机关",
              "TaxAuthoritiesCode": "主管税务机关代码",
              "VIN": "车辆识别代码",
              "VehicleModel": "厂牌型号",
              "VehicleEngineCode": "发动机号码",
              "CertificateNumber": "合格证号",
              "InspectionNumber": "商检单号",
              "MachineID": "机器编号",
              "TaxRate": "税率",
              "Tonnage": "吨位",
              "FormName": "发票联名",
              "TaxNum": "完税凭证号码",
              "MaxPeopleNum": "限乘人数",
              "Origin": "产地",
              "MachineNumber": "机打发票号码",
              "BuyerAddress": "购买方地址",
              "BuyerTel": "购买方电话",
              "CompanyName": "二手车市场",
              "CompanyTaxID": "二手车市场纳税人识别号",
              "CompanyBankAccount": "二手车市场开户银行和账号",
              "CompanyTel": "二手车市场电话",
              "CompanyAddress": "二手车市场地址",
              "TransferAdministrationName": "转入地车辆管理所名称",
              "LicensePlate": "车牌号",
              "RegistrationNumber": "登记证号",
              "Seller": "销售方名称",
              "SellerTaxID": "销售方纳税人识别号",
              "Category": "种类",
              "TaxSealContent": "税务局章内容",
              "VatRollItems": "条目",
              "TimeGetOff": "下车时间",
              "Price": "单价",
              "Mileage": "里程",
              "Place": "发票所在地",
              "FuelFee": "燃油附加费",
              "BookingCallFee": "预约叫车服务费",
              "SerialNumber": "印刷序号",
              "AgentCode": "销售单位代号",
              "AgentCodeFirst": "销售单位代号第一行",
              "AgentCodeSecond": "销售单位代号第二行",
              "Fare": "票价",
              "FuelSurcharge": "燃油附加费",
              "AirDevelopmentFund": "民航发展基金",
              "Insurance": "保险费",
              "DomesticInternationalTag": "国内国际标签",
              "DateStart": "客票生效日期",
              "DateEnd": "有效截至日期",
              "Endorsement": "签注",
              "FlightItems": "条目",
              "SellerCompany": "收款单位名称",
              "OtherInfo": "其他信息",
              "PaymentCode": "缴款码",
              "ReceiveUnitCode": "执收单位编码",
              "Receiver": "执收单位名称",
              "Operator": "经办人",
              "PayerAccount": "付款人账号",
              "PayerBank": "付款人开户银行",
              "ReceiverAccount": "收款人账号",
              "ReceiverBank": "收款人开户银行",
              "NonTaxItems": "条目",
              "Name": "乘车人姓名",
              "StationGetOn": "出发车站",
              "StationGetOff": "到达车站",
              "Seat": "座位类型",
              "GateNumber": "检票口",
              "TrainNumber": "车次",
              "HandlingFee": "手续费",
              "OriginalFare": "原票价",
              "PickUpAddress": "取票地址",
              "TicketChange": "是否始发改签",
              "AdditionalFare": "加收票价",
              "ReceiptNumber": "收据号码",
              "ReimburseOnlyMark": "是否仅供报销使用（0：没有，1：有）"
            }
          }
        },
        {
          "title": "增值税发票识别",
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "paramsList": [
            {
              "isHide": true,
              "key": "IsPdf",
              "label": "识别语言",
              "default": true,
              "type": "input"
            }
          ],
          "text": "支持增值税专用发票、增值税普通发票、增值税电子发票全字段的内容检测和识别，包括发票代码、发票号码、开票日期、合计金额、校验码、税率等。",
          "action": "VatInvoiceOCR",
          "url": "https://cloud.tencent.com/document/product/866/36210",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/VatInvoiceOCR/VatInvoiceOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/VatInvoiceOCR/VatInvoiceOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/VatInvoiceOCR/VatInvoiceOCR3.jpg"
          ],
          "resultConfig": {
            "type": "keyMap",
            "firstField": "VatInvoiceInfos",
            "hide": "密码区"
          }
        },
        {
          "title": "OFD发票识别",
          "acceptList": [
            "ofd"
          ],
          "prefix": "OfdFile",
          "text": "支持OFD格式的增值税电子普通发票和增值税电子专用发票的识别，返回发票代码、发票号码、开票日期、验证码、机器编号、密码区，购买方和销售方信息，包括名称、纳税人识别号、地址电话、开户行及账号，以及价税合计、开票人、收款人、复核人、税额、不含税金额等字段信息。",
          "action": "VerifyOfdVatInvoiceOCR",
          "url": "https://cloud.tencent.com/document/product/866/52296",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/VerifyOfdVatInvoiceOCR/VerifyOfdVatInvoiceOCR1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/VerifyOfdVatInvoiceOCR/VerifyOfdVatInvoiceOCR2.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/VerifyOfdVatInvoiceOCR/VerifyOfdVatInvoiceOCR3.png"
          ],
          "fakeImglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/VerifyOfdVatInvoiceOCR/VerifyOfdVatInvoiceOCR1.ofd",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/VerifyOfdVatInvoiceOCR/VerifyOfdVatInvoiceOCR2.ofd",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/VerifyOfdVatInvoiceOCR/VerifyOfdVatInvoiceOCR3.ofd"
          ],
          "resultConfig": {
            "type": "childMap",
            "fieldsMap": {
              "Buyer": {
                "_Label": "购买方",
                "AddrTel": "地 址、电 话",
                "FinancialAccount": "开户行及账号",
                "Name": "名称",
                "TaxId": "纳税人识别号"
              },
              "Checker": "复核人",
              "InvoiceCheckCode": "验证码",
              "InvoiceClerk": "开票人",
              "InvoiceCode": "发票代码",
              "InvoiceNumber": "发票号码",
              "IssueDate": "开票日期",
              "MachineNumber": "机器编号",
              "Note": "备注",
              "Payee": "收款人",
              "Seller": {
                "_Label": "销售方",
                "AddrTel": "地 址、电 话",
                "FinancialAccount": "开户行及账号",
                "Name": "名称",
                "TaxId": "纳税人识别号"
              }
            }
          }
        },
        {
          "title": "运单识别",
          "text": "支持市面上主流版式电子运单的识别，包括收件人和寄件人的姓名、电话、地址以及运单号等字段。",
          "action": "WaybillOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/34934",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/WaybillOCR/WaybillOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/WaybillOCR/WaybillOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/WaybillOCR/WaybillOCR3.jpg"
          ],
          "resultConfig": {
            "type": "childMap",
            "firstField": "TextDetections",
            "fieldsMap": {
              "RecName": {
                "Text": "收件人姓名"
              },
              "RecNum": {
                "Text": "收件人电话"
              },
              "RecAddr": {
                "Text": "收件人地址"
              },
              "SenderName": {
                "Text": "寄件人姓名"
              },
              "SenderNum": {
                "Text": "寄件人电话"
              },
              "SenderAddr": {
                "Text": "寄件人地址"
              },
              "WaybillNum": {
                "Text": "运单号"
              }
            }
          }
        },
        {
          "title": "集装箱识别",
          "beta": false,
          "text": "支持集装箱箱门信息识别，识别字段包括集装箱箱号、类型、总重量、有效承重、容量、自身重量，具备集装箱箱号、类型不完整或者不清晰的告警功能。",
          "action": "RecognizeContainerOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/63810",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/RecognizeContainerOCR/RecognizeContainerOCR1.png"
          ],
          "resultConfig": {
            "type": "map",
            "fieldsMap": {
              "ContainerId": "集装箱箱号",
              "ContainerType": "集装箱类型",
              "GrossKG": "集装箱总重量(KG)",
              "GrossLB": "集装箱总重量(LB)",
              "PayloadKG": "集装箱有效承重(KG)",
              "PayloadLB": "集装箱有效承重(LB)",
              "CapacityM3": "集装箱容量(立方米)",
              "CapacityFT3": "集装箱容量(立英尺)",
              "TareKG": "集装箱自身重量(KG)",
              "TareLB": "集装箱自身重量(LB)"
            }
          }
        },
        {
          "title": "医疗票据识别",
          "beta": false,
          "text": "医疗发票识别目前支持全国统一门诊发票、全国统一住院发票、以及部分地方的门诊和住院发票的识别。",
          "action": "RecognizeMedicalInvoiceOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/80326",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/RecognizeMedicalInvoiceOCR/RecognizeMedicalInvoiceOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/RecognizeMedicalInvoiceOCR/RecognizeMedicalInvoiceOCR2.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/RecognizeMedicalInvoiceOCR/RecognizeMedicalInvoiceOCR3.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/RecognizeMedicalInvoiceOCR/RecognizeMedicalInvoiceOCR4.png"
          ],
          "paramsList": [
            {
              "isHide": true,
              "key": "IsPdf",
              "label": "识别语言",
              "default": true,
              "type": "input"
            }
          ],
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "resultConfig": {
            "type": "keyMap",
            "firstField": "MedicalInvoiceInfos",
            "secondField": "MedicalInvoiceItems",
            "key1": "Name",
            "key2": "Content"
          }
        },
        {
          "title": "完税证明识别",
          "beta": false,
          "text": "支持对完税证明的税号、纳税人识别号、纳税人名称、金额合计大写、金额合计小写、填发日期、税务机关、填票人等关键字段的识别。",
          "action": "DutyPaidProofOCR",
          "url": "https://cloud.tencent.com/document/product/866/37839",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/DutyPaidProofOCR/DutyPaidProofOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/DutyPaidProofOCR/DutyPaidProofOCR2.jpg"
          ],
          "paramsList": [
            {
              "isHide": true,
              "key": "IsPdf",
              "label": "识别语言",
              "default": true,
              "type": "input"
            }
          ],
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "resultConfig": {
            "type": "keyMap",
            "firstField": "DutyPaidProofInfos"
          }
        },
        {
          "title": "银行回单识别",
          "beta": false,
          "text": "支持银行回单全字段的识别，包括付款开户行、收款开户行、付款账号、收款账号、回单类型、回单编号、币种、流水号、凭证号码、交易机构、交易金额、手续费、日期等字段信息。",
          "action": "BankSlipOCR",
          "url": "https://cloud.tencent.com/document/product/866/57674",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/BankSlipOCR/BankSlipOCR1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/BankSlipOCR/BankSlipOCR3.png"
          ],
          "paramsList": [
            {
              "isHide": true,
              "key": "IsPdf",
              "label": "识别语言",
              "default": true,
              "type": "input"
            }
          ],
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "resultConfig": {
            "type": "keyMap",
            "firstField": "BankSlipInfos"
          }
        },
        {
          "title": "网约车行程单识别",
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "beta": false,
          "text": "支持网约车行程单关键字段的识别，包括行程起止日期、上车时间、起点、终点、里程、金额等字段。",
          "action": "RecognizeOnlineTaxiItineraryOCR",
          "paramsList": [
            {
              "isHide": true,
              "key": "IsPdf",
              "label": "识别语言",
              "default": true,
              "type": "input"
            }
          ],
          "url": "https://cloud.tencent.com/document/product/866/62614",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/invoice/RecognizeOnlineTaxiItineraryOCR/RecognizeOnlineTaxiItineraryOCR1.png"
          ],
          "resultConfig": {
            "type": "keyMap",
            "firstField": "OnlineTaxiItineraryInfos"
          }
        }
      ]
    },
    {
      "title": "特定场景识别",
      "icon": "ai-demo-icon-32-ocr-car",
      "children": [
        {
          "title": "印章识别",
          "text": "支持各类印章，包括发票章，财务章等，适用于公文，票据等场景。",
          "action": "SealOCR",
          "url": "https://cloud.tencent.com/document/product/866/45807",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/SealOCR/SealOCR1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/document/SealOCR/SealOCR2.png"
          ],
          "acceptList": [
            "jpg",
            "jpeg",
            "png",
            "pdf"
          ],
          "resultConfig": {
            "type": "SealOCR",
            "firstField": "SealInfos",
            "secondField": "SealBody",
            "SealShapeList": {
              "0": "圆形印章",
              "1": "椭圆形印章",
              "2": "方形印章",
              "3": "菱形印章",
              "4": "三角形印章"
            }
          },
          "rectConfig": {
            "positionType": "1", //  1:x y height width
            "firstField": "SealInfos",
            "secondField": "Location",
            "key":"SealBody"
          }
        },
        {
          "title": "车牌识别",
          "text": "支持对中国大陆机动车车牌的自动定位和识别，返回地域编号和车牌号信息。",
          "action": "LicensePlateOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/36211",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/LicensePlateOCR/LicensePlateOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/LicensePlateOCR/LicensePlateOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/LicensePlateOCR/LicensePlateOCR3.jpg"
          ],
          "resultConfig": {
            "type": "list",
            "firstField": "LicensePlateInfos",
            "secondField": "Number"
          },
          "rectConfig": {
            "positionType": "1", //  1:x y height width
            "firstField": "LicensePlateInfos",
            "secondField": "Rect",
            "key":"Number"
          }
        },
        {
          "title": "车辆VIN码识别",
          "text": "支持图片内车辆识别代号（VIN）的检测和识别。",
          "action": "VinOCR",
          "acceptList": [
            "jpg",
            "jpeg",
            "png"
          ],
          "url": "https://cloud.tencent.com/document/product/866/34935",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/VinOCR/VinOCR1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/VinOCR/VinOCR2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/vehicle/VinOCR/VinOCR3.jpg"
          ],
          "resultConfig": {
            "type": "map",
            "fieldsMap": {
              "Vin": "VIN码"
            }
          }
        }
      ]
    }
  ],
  [
    {
      "title": "文本图像增强",
      "icon": "ai-demo-icon-32-ocr-car",
      "children": [
        {
          "title": "文本图像增强",
          "text": "文本图像增强是面向文档类图片提供的图像增强处理能力，包括切边增强、图像矫正、阴影去除、摩尔纹去除等；可以有效优化文档类的图片质量，提升文字的清晰度。",
          "action": "ImageEnhancement",
          "url": "https://cloud.tencent.com/document/product/866/80801",
          "imglist": [
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/1-1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/1-2.jpeg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/302-1.jpeg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/302-2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/301-1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/301-2.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/204-1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/204-2.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/208-1.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/208-2.jpg",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/205-1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/205-2.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/202-0.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/202-1.png",
            "https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/tie/static/images/202-2.png"
          ],
          "paramsList": [
            {
              "isHide": true,
              "key": "ReturnImage",
              "label": "返回图片",
              "default": "preprocess",
              "type": "input"
            },
            {
              "key": "TaskType",
              "label": "图像增强方法",
              "default": 1,
              "type": "select",
              "options": [
                {
                  "value": 1,
                  "text": "切边增强",
                  "tip": "自动判断照片中的文件边缘，去除无关背景，突出文件信息"
                },
                {
                  "value": 302,
                  "text": "去除阴影",
                  "tip": "检测出图片的阴影，并进行去除，提升图片质量"
                },
                {
                  "value": 301,
                  "text": "去摩尔纹",
                  "tip": "检测出图片中的屏幕摩尔纹，并进行去除，提升图片质量"
                },
                {
                  "value": 204,
                  "text": "提亮模式",
                  "tip": "提升图片整体的亮度，方便文件阅读，适合对拍摄光线较暗的图片进行处理"
                },
                {
                  "value": 208,
                  "text": "文字锐化",
                  "tip": "对图片中的文字边缘进行增强，增加锐度使文字更清晰，适合对非彩色图片处理"
                },
                {
                  "value": 205,
                  "text": "灰度模式",
                  "tip": "将彩色图片转化为灰度图，类似于单色(带有深浅)打印效果。"
                },
                {
                  "value": 202,
                  "text": "黑白模式",
                  "tip": "将图片转化为黑白二值图，使得转化后的黑白图保留原始图片中的边缘信息"
                },
                {
                  "value": 207,
                  "text": "省墨模式",
                  "tip": "将图片转化为黑白图，实现节约墨水的效果"
                }
              ]
            }
          ],
          "resultConfig": {
            "type": "Image"
          }
        }
      ]
    }
  ]
]


export const errPdf = 'https://ocr-demo-1254418846.cos.ap-guangzhou.myqcloud.com/file_fail.png'