import React, { useState, useEffect} from "react";
import OcrLayout from "./components/OcrLayout.jsx";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { getQueryString } from './utils/index' ;


function Home(props) {
  useEffect(() => {
    const action = getQueryString('action');
    console.log('action',action)
  },[])

  return (<>
    <Router>
      <Switch>
        <Route path="/about">
          <OcrLayout />
        </Route>
        <Route path="/users">
          <OcrLayout />
        </Route>
        <Route path="/">
          <OcrLayout />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  </>

  );
}

export default Home;