import React, { useState, useEffect, useRef } from "react";
// import JsonView from '@uiw/react-json-view';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import {
  Layout,
  ChatWindow,
  ExplainPanel,
  MessageBar,
  CodeBox,
  Msg,
  Loading
} from "@tencent/universe-ui/build/lib";
import { chartOcrRequest, chartOcrFeedBackRequest } from '../utils/api.js';
import { copyText } from '../utils/index.js';

const questionList = [
  '请列出所有的key/value信息',
  '图片中的手写OCR内容是什么',
  '请概括该文档图片的主要内容',
  '图中印章内容是什么',
  '图片中有哪些关键字段',
  '图片中的OCR内容是什么',
]
const AnswerGroup = ({ answer, image, onscroll }) => {
  const [showRequest, setShowRequest] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [feedStatus, setFeedStatus] = useState('');

  useEffect(() => {
    onscroll && onscroll();
  }, [showResponse, showRequest]);

  const handleFeedBack = async (status) => {
    const backStatus = feedStatus
    setFeedStatus(status);
    const res = await chartOcrFeedBackRequest({
      ChatOCRRequestId: answer.RequestId,
      Question: answer.Question,
      Answer: answer.Answer,
      [image.prefix]: image.url,
      FeedBack: status,
    })
    if (res.error) {
      Msg.error({
        message: res.message
      });
      // 如果提交失败，回退到上一次点赞状态
      setFeedStatus(backStatus);
    } else {
      Msg.success({
        message: '提交成功',
      });
    }
  }
  return <>
    <MessageBar
      role="self"
      tagList={[{ text: answer.tips }]}
      text={answer.Question}
    />
    {
      answer.Answer?.length > 0 && <MessageBar
        text={<>
          {
            answer.Answer?.map((i, key) => (<p index={key}>{i}</p>))
          }
        </>}
        labelList={[
          { iconSvg: copyIcon, text: "复制", onClick: (e) => { copyText(JSON.stringify(answer.Answer)) } },
          { text: "Request", active: showRequest, onClick: (e) => { setShowRequest(!showRequest) } },
          { text: "Response", active: showResponse, onClick: (e) => { setShowResponse(!showResponse) } },
        ]}
        iconButtonList={[
          { iconSvg: goodIcon, active: feedStatus === '1', onClick: (e) => { handleFeedBack('1') } },
          { iconSvg: badIcon, active: feedStatus === '0', onClick: (e) => { handleFeedBack('0') } },
        ]}
      >
        {
          showRequest &&
          <CodeBox title="Request信息">
            <JsonView src={answer.request} name={false} theme="grayscale:inverted" displayDataTypes={false} />
          </CodeBox>
        }
        {
          showResponse && <CodeBox title="Response信息">
            <JsonView src={answer} name={false} theme="grayscale:inverted" displayDataTypes={false} />
          </CodeBox>
        }


      </MessageBar>
    }

  </>
}
const { Sider } = Layout;

const copyIcon = (
  <svg viewBox="0 0 16 16" fill="currentColor">
    <path d="M13.73529,5.375 L13.73529,14.125 L5.79413,14.125 L5.79413,5.375 L13.73529,5.375 Z M11.97059,7.125 L7.55883,7.125 L7.55883,12.375 L11.97059,12.375 L11.97059,7.125 Z M10.20589,1.875 L10.20589,3.625 L4.02942,3.625 L4.02942,10.625 L2.26471,10.625 L2.26471,1.875 L10.20589,1.875 Z"></path>
  </svg>
);

const goodIcon = (
  <svg viewBox="0 0 16 16" fill="currentColor">
    <path d="M6.97906795,1.1275875 L7.83173795,1.2696925 C9.11753795,1.4840025 10.060018,2.5965025 10.060018,3.9000825 L10.060018,5.5391625 L13.606318,5.5391625 C14.430318,5.5391625 15.057018,6.2789825 14.921518,7.0916625 L13.810518,13.7583125 C13.703318,14.4012125 13.147118,14.8724125 12.495318,14.8724125 L4.39333795,14.8724125 L4.39333795,7.4072625 L6.97906795,1.1275875 Z M7.80206795,2.6308225 L5.72666795,7.6710625 L5.72666795,13.5391125 L12.495318,13.5391125 L13.606318,6.8724625 L8.72666795,6.8724625 L8.72666795,3.9000825 C8.72666795,3.3142225 8.34593795,2.8055525 7.80206795,2.6308225 Z M2.39333795,6.8724625 L2.39333795,14.8724125 L1.05999795,14.8724125 L1.05999795,6.8724625 L2.39333795,6.8724625 Z"></path>
  </svg>
);

const badIcon = (
  <svg viewBox="0 0 16 16" fill="currentColor">
    <path d="M6.97906795,14.872425 L7.83173795,14.730325 C9.11753795,14.516025 10.060018,13.403525 10.060018,12.099925 L10.060018,10.460925 L13.606318,10.460925 C14.430318,10.460925 15.057018,9.721055 14.921518,8.908375 L13.810518,2.241705 C13.703318,1.598775 13.147118,1.127575 12.495318,1.127575 L4.39333795,1.127575 L4.39333795,8.592775 L6.97906795,14.872425 Z M7.80206795,13.369225 L5.72666795,8.328975 L5.72666795,2.460905 L12.495318,2.460905 L13.606318,9.127575 L8.72666795,9.127575 L8.72666795,12.099925 C8.72666795,12.685825 8.34593795,13.194525 7.80206795,13.369225 Z M2.39333795,9.127575 L2.39333795,1.127575 L1.05999795,1.127575 L1.05999795,9.127575 L2.39333795,9.127575 Z"></path>
  </svg>
);

function ModChat({ detail, image, onImageError }) {
  const [visible, setVisible] = useState(false);
  const [Question, setQuestion] = useState('');
  const [Answers, setAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const messagesEnd = useRef(null);

  const scrollToBottom = () => {
    if (messagesEnd && messagesEnd.current) {
      messagesEnd.current.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });
    }
  };

  useEffect(() => {
    handleStart({ question: '', questionTips: '示例', answers: [] });
  }, [image]);

  useEffect(() => {
    scrollToBottom();
  }, [Answers]);

  const handleStart = async ({ question = Question, questionTips, answers = Answers }) => {
    let _answer = question ? answers.concat({
      Question: question,
      tips: questionTips ? questionTips : (question ? '' : '示例')
    }) : answers;
    setAnswers(_answer);
    // setAnswers(answers);
    setQuestion(question);
    setIsLoading(true);
    const res = await chartOcrRequest({
      [image.prefix]: image.url,
      IsPdf: true,
      Question: question
    }, question ? false : image.isExample)
    setIsLoading(false);
    if (res.isImageError) {
      onImageError(true);
    } else {
      onImageError(false);
    }
    if (res.error) {
      Msg.error({
        message: res.message,
      });
    } else {
      // _answer = 
      if(!question) 
      return 
      const _answers = answers.concat(res.Answers.map(a => ({
        ...a,
        RequestId: res.RequestId,
        request: {
          [image.prefix]: image.url,
          IsPdf: true,
          Question: question
        },
        tips: questionTips ? questionTips : (question ? '' : '示例')
      })));
      setAnswers(_answers);
      setQuestion('');
    }
  }
  return (
    <>
      <Sider.Header
        title="识别结果"
      />
      <Sider.Body>
        <ChatWindow>
          <ChatWindow.Interface>
            <ExplainPanel
              iconUrl="http://assets.woa.com/open_proj/proj_qcloud_v2/tpm-ocr/src/public/ExplainPanel/ExplainPanel-icon.svg"
              title="智能识别助手"
              description={[
                <>
                  您好，欢迎使用通用识别文字智能，我可以帮您处理图片中含文字的相关内容，提升业务效率。您可以选择问题进行提问。
                </>,
                <>
                目前本功能还未完全开放，如有更多需求，您可以<a style={{color: '#006eff'}} href="https://cloud.tencent.com/online-service?source=PRESALE&from=connect-us" target="_blank"><b>联系商务团队</b></a>。
                </>
              ]}
            />

            <MessageBar.Group>
              {
                Answers?.map((answer, index) => (<AnswerGroup answer={answer} key={index} image={image} onscroll={scrollToBottom} />))
              }
            </MessageBar.Group>
            {
              isLoading && <div className="unv-answer-loading">
                <div className="unv-answer-loading__icon"></div>
                <div className="unv-answer-loading__tip">图片内容识别中...</div>
              </div>
            }
            <div className="message_scroll__bar" style={{ clear: 'both', height: '1px', width: '100%' }} ref={messagesEnd}></div>
          </ChatWindow.Interface>
          {
            !isLoading && <div className="question_container">
            <div>
              以下是 OCR 可以回答的一些问题
            </div>
            <div className="question_item__container">
              {
                questionList.map((question, index) => {
                  return <div className="question__item" onClick={(res) => {
                    if(!question) return;
                    setQuestion(question);
                    setVisible(false);
                    handleStart({
                      question,
                      questionTips:'预设'});
                  }}>
                    {question}
                  </div>
                })
              }
  
            </div>
            </div>
          }
          
        </ChatWindow>
      </Sider.Body>
      {isLoading && <Loading />}
    </>
  );
}

export default ModChat;