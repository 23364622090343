import React from "react";
import ReactDOM from "react-dom"
import "./index.scss";
import Home from "./Home.jsx";
import '@tencent/universe-ui/build/lib/style.js';
import "./var.scss";

import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
export default function App() {
  return (
    <Router>
      <Route path="/" component={Home} />
      <Route path="/about" component={Home} />
        {/* <Route path="*" component={Home} /> */}
    </Router>
  )
}

const root = document.getElementById("root")
ReactDOM.render(<App />, root)

